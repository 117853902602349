section.management {
  padding: 100px 0px;
}

.management-container {
  h3 {
    font-family: "PoppinsBold";
  }
  .club-members {
    gap: 30px !important;
  }
}
