.about {
  padding: 100px 10px;
}

.main-title {
  font-family: "Oraqle" !important;
  color: $blue;
  font-size: 64px;
}

.about-image {
  border-radius: 10px;
  width: 80%;
}

.about-content {
  h2 {
    font-family: "PoppinsBold" !important;
  }
}

@media only screen and (max-width: 769px) {
  .about-image {
    width: 100%;
  }
  .about-content {
    padding-top: 20px;
  }
}

.arrows {
  float: right;
  display: flex;
  gap: 10px;
}
