.modal-body h3 {
  font-family: "PoppinsBold";
}

.modal-transition-enter {
  opacity: 0;
  transform: translateY(-50px);
}

.modal-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms ease-in-out;
}

.modal-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.modal-transition-exit-active {
  opacity: 0;
  transform: translateY(-50px);
  transition: all 300ms ease-in-out;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-body {
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  max-width: 600px;
  width: 100%;
  overflow-y: auto;
}

table {
  width: 100%;
}

table,
tr,
th,
td {
  border: 1px solid #ddd;
  padding: 5px;
}
