header {
  background: linear-gradient(
      0deg,
      rgba(37, 37, 37, 0.4),
      rgba(37, 37, 37, 0.4)
    ),
    url("../../assets/img/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
}

.wave {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 40%;
}

.logo {
  gap: 10px;
}

.logo-img {
  width: 80px;
  height: 80px;
}

.links li {
  list-style: none;
  margin: 0 10px;

  a {
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
  }
}

nav {
  padding-top: 15px;
  z-index: 1000;
  position: relative;
}

.logo h1 {
  font-family: "PoppinsBold";
  font-size: 30px;
}

.smallWave {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.blueBtn {
  background-color: $blue;
  padding: 20px 30px;
  border-radius: 50px;
  color: $white;
}

.header--content {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $white;
  align-items: self-start;

  h1 {
    font-family: "PoppinsBold";
    margin-bottom: 20px;
  }

  p {
    font-family: "PoppinsLight";
    margin-bottom: 20px;
    width: 50%;
  }
}

.overlay {
  height: 100%;
  width: 0%;
  position: fixed;
  right: 0;
  background-color: rgb(33, 33, 33);
  top: 0;
  font-family: "Poppins";
  transition: 0.6s;
  z-index: 999;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 10px;
    padding: 0;

    li {
      text-align: center;
      font-size: 20px;
      padding-bottom: 10px;
      width: max-content;
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;

      a {
        color: $white;
        text-decoration: none;
      }
    }
  }
}

.hamburger {
  display: none;
  z-index: 1000;
}

@media only screen and (max-width: 995px) {
  header .links {
    display: none !important;
  }
  .hamburger {
    display: block;
  }
}
