footer {
  background-color: rgb(39, 39, 39);
  width: 100%;
  height: 30vh;
  .footer__wrapper {
    margin: auto;
    width: 90%;
    padding-top: 50px;
  }

  .footer_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h1 {
    font-weight: 900;
  }
  h4 {
    font-family: "Poppins-Light";
  }

  ul {
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0;
    li {
      list-style: none;

      a {
        text-decoration: none;
        color: $white;
        width: max-content;
      }
    }
  }
  p {
    margin: 0;
  }
}

.info {
  float: right;
  display: flex;
  gap: 20px;
  a {
    text-decoration: underline;
    color: $white;
    width: max-content;
  }
}

.copyright__wrapper {
  padding-bottom: 50px;
  padding-top: 20px;
  color: #fff;
}

@media only screen and (max-width: 1100px) {
  footer .footer_wrap {
    flex-direction: column;
    gap: 30px;
  }
}

@media only screen and (max-width: 570px) {
  footer ul {
    flex-direction: column;
  }
  footer {
    height: 100%;
  }

  footer .footer_wrap {
    align-items: flex-start;
  }
}

.vik {
  color: $blue;
  font-weight: 900;
  text-decoration: none;

  &:hover {
    color: $blue;
  }
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 10px;
  color: $white;
  font-family: "PoppinsBold";
}
