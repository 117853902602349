section.why {
  padding: 100px 0px;

  .name {
    font-family: "PoppinsBold";
  }
}

.why-img {
  border-radius: 10px;
  width: 100%;
}
