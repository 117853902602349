section.trainers {
  padding: 100px 0;
  .trainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h3 {
      font-family: "PoppinsBold";
    }
  }
}
